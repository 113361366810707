import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import 'normalize.css';

import theme, { GlobalStyle } from '../theme';

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <div>{children}</div>
    </>
  </ThemeProvider>
);

Root.propTypes = {
  children: PropTypes.node.isRequired
};

export default Root;
