const font = {
  family: {
    sansSerif: 'proxima-nova, sans-serif'
  },
  weight: {
    regular: 400,
    bold: 700
  }
};

export default font;
