import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: ${({ theme }) => theme.color['body-background']};
    border-top: 8px solid ${({ theme }) => theme.color['body-border']};
    color: ${({ theme }) => theme.color['body-color']};
    font-family: ${({ theme }) => theme.font.family.sansSerif};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyle;
