import colors from './colors';
import font from './font';

const theme = {
  colors,
  font,

  color: {
    'body-background': colors['blue-grey-050'],
    'body-border': colors['blue-grey-900'],
    'body-color': colors['blue-grey-900']
  }
};

export default theme;
